import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Card } from 'react-bootstrap';
import BarChart from '../Charts/BarChart';
import GraphLoader from '../../Assets/GraphLoader.gif';
import { useSelector } from 'react-redux';

const Home = () => {

  const userState = useSelector(state => state.loginData.userData);
  const financialYear = useSelector(state => state.activeData.activeMenu.activeTab)
  const [zoneLoader, setZoneLoader] = useState(false);
  const [zoneLabels, setZoneLabels] = useState('');
  const [count, setCount] = useState('');
  const [zoneList, setZoneList] = useState('');
  const [zone, setZone] = useState('');

  useEffect(() => {
    getZoneList();
  }, []);

  useEffect(() => {
    // Fetch zonal update data when the component mounts or when the zone changes
    getZonalUpdateData();
  }, [zone]);

  // list of zonelist
  const getZoneList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/zoneData/getZoneData', {
        method: 'GET',
      });
      if (response) {
        const responseData = await response.json();
        setZoneList(responseData.data);

        if (responseData?.data && responseData?.data?.length > 0) {
          setZone(responseData.data[0]?.zonekey);
        }
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (err) {
      console.error('Error while getting zone', err);
    }
  };

  // for zonalupdate data graph
  const getZonalUpdateData = async () => {
    const payload = {
      zonekey: zone,
      financialYear : financialYear
    };

    try {
      setZoneLoader(true);
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/graph/zonalUpdates2', {
        method: 'POST',
        headers: { 'Content-Type' : 'application/json' },
        body: JSON.stringify(payload),
      });

      if (response) {
        const responseData = await response.json();
        const chartLabels = responseData?.labels || [];
        const chartData = responseData?.values || [];

        // Update state variables
        setZoneLabels(chartLabels);
        setCount(chartData);
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch(err) {
      console.error('Error while getting zonal update data', err);
    } finally {
      setZoneLoader(false);
    }
  };

  return (
    <div>
      <Container>
      <Row className='welcome d-flex justify-content-center'>
          <h4 className='mb-1'>Welcome, {userState.usertype_keyname[0] === "admin" ? "Admin !" : ""}
            {userState.usertype_keyname[0] === "supervisor" ? "SuperVisor !" : ""}
            {userState.usertype_keyname[0] === "zonalofficer" ? "Zonal Officer !" : ""} </h4>
          <p className='ms-2'>Dashboard of Pimpri Chinchwad Muncipal Corporation Tax Application...</p>
        </Row>

        <Row className='justify-content-end'>
          <Col xs={8} md={2} className='mb-2'>
            <Form.Label>Search by zone</Form.Label>
            <Form.Select onChange={(e) => setZone(e.target.value)} value={zone}>
              {zoneList !== undefined && zoneList.length > 0 && zoneList !== null ? (
                zoneList.map((row) => (
                  <option key={row?.zonekey} value={row?.zonekey}>
                    {row?.zonename} - {row?.zoneno}
                  </option>
                ))
              ) : (
                <option value=''></option>
              )}
            </Form.Select>
          </Col>
        </Row>

        <Row className='mb-3'>
          <Col md={12} className='my-2 d-flex justify-content-center align-items-center'>
            <Card className='ChartCard' style={{ height: '280px', maxHeight: '280px', width: '100%', padding: '20px 10px 12px 0px' }}>
              <h6 className='mb-0 mx-3'>Zone wise Data</h6>
              {zoneLoader ? (
                <center>
                  <img src={GraphLoader} width='150' maxHeight='250px' />
                </center>
              ) : (
                <BarChart
                  indexAxis='x'
                  chartLabels={zoneLabels}
                  chartLabel={['count']}
                  ColorArray={['rgba(28, 255, 68, 0.43)']}
                  BorderArray={['rgba(0, 225, 39, 1)']}
                  chartData={count}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;

import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Tab, Nav, Table, Spinner, Form, Button } from 'react-bootstrap';
import moment from 'moment';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';

const TotalCalling = () => {

    const Zoneoptions = {
        page: {
            margin: 10,
        },
        filename: 'zone Wise Calling.pdf'
    };
    const UserOptions = {
        page: {
            margin: 10,
        },
        filename: 'User Wise Calling.pdf'
    };

    const financialYear = useSelector(state => state.activeData.activeMenu.activeTab)

    const zoneWiseTarget = useRef();
    const userWiseTarget = useRef();

    const [key, setKey] = useState('ZoneWise');
    const [zoneLoader, setZoneLoader] = useState(false);
    const [userLoader, setUserLoader] = useState(false);
    const [zoneWiseCalling, setZoneWiseCalling] = useState('');
    const [userWiseCalling, setUserWiseCalling] = useState('');
    const [date, setDate] = useState('');

    const [zone, setZone] = useState('');
    const [zoneList, setZoneList] = useState('');

    useEffect(() => {
        getZone();
    }, [])

    useEffect(() => {
        getZoneWiseTotalCalling();
        getUserWiseTotalCalling();
    }, [date, zone, financialYear])

    const getZone = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/zoneData/getZoneData', {
            method: "GET"
        }).then((res) => res.json()).then((response) => {
            setZoneList(response.data);
        }).catch((err) => {
            console.log("err while gettting zone=>", err);
        })
    }

    const getZoneWiseTotalCalling = async () => {
        setZoneLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/tc/zoneWiseReport', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                zonename: zone,
                callingDate: date,
                financialYear : financialYear
            })
        }).then((res) => res.json()).then((response) => {
            setZoneWiseCalling(response?.data);
            setZoneLoader(false);
        }).catch((Err) => {
            console.log("Err while getting zone wise calling", Err);
            setZoneLoader(false);
        })
    }

    const getUserWiseTotalCalling = async () => {
        setUserLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/user/getCombinedCallingUsers', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                zonename: zone,
                callingDate: date,
                financialYear : financialYear
            })
        }).then((res) => res.json()).then((response) => {
            setUserWiseCalling(response?.data);
            setUserLoader(false);
        }).catch((Err) => {
            console.log("Err while getting user wise calling", Err);
            setUserLoader(false);
        })
    }

    // const handleDownload = () => {
    //     const pdfExporter = usePDF;

    //     const options = {
    //       orientation: 'portrait', // or 'landscape'
    //       unit: 'mm',
    //       format: 'a4',
    //     };

    //     pdfExporter.generatePdf(containerRef.current, options).then((pdf) => {
    //       const blob = new Blob([pdf], { type: 'application/pdf' });
    //       const link = document.createElement('a');
    //       link.href = URL.createObjectURL(blob);
    //       link.download = 'TwoTables.pdf';
    //       link.click();
    //     });
    //   };

    return (
        <div className='TotalCalling mb-5'>
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                <Row className='justify-content-between align-items-center'>
                    <Col md={4} className='d-flex mb-3 mb-md-0'>
                        <div className='me-3'>
                            <Form.Label>Search by date</Form.Label>
                            <Form.Control type="date" value={date} max={new Date().toISOString().split("T")[0]} onChange={(e) => setDate(moment(e.target.value).format("YYYY-MM-DD"))} />
                        </div>

                        <div>
                            <Form.Label>Search by zone</Form.Label>
                            <Form.Select onChange={(e) => setZone(e.target.value)}>
                                <option value="" selected disabled>Select zone</option>
                                {
                                    zoneList !== undefined && zoneList.length > 0 && zoneList !== null ?
                                        zoneList?.map((row) => {
                                            return (
                                                <option value={row?.zonename}>{row?.zonename + -row?.zoneno}</option>
                                            )
                                        }) : <option value=""></option>
                                }
                            </Form.Select>
                        </div>
                    </Col>
                    <Col md={3}>
                        <Nav variant="pills" className="flex-row justify-content-end">
                            <Nav.Item>
                                <Nav.Link eventKey="ZoneWise">Zone Wise</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="UserWise">User Wise</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>


                <Tab.Content className='mt-3 mt-md-0'>
                    <Tab.Pane eventKey="ZoneWise">

                        <Button className='btn-cancel mx-auto' onClick={() => generatePDF(zoneWiseTarget, Zoneoptions)}>
                            <Icon.ArrowDownCircle size={14} className='me-2' />Download ZoneWise PDF
                        </Button>

                        <div className="container tbl-container p-0 mt-4" ref={zoneWiseTarget}>
                            <div className="row tbl-fixed p-0">
                                <Table responsive="lg" hover>
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>Zone</th>
                                            <th>Call Connected</th>
                                            <th>Call Not Connected</th>
                                            <th>Dail Call Connected</th>
                                            <th>Dail Call Not Connected</th>
                                            <th>Totals</th>
                                        </tr>
                                    </thead>

                                    {zoneLoader ? (
                                        <Spinner variant="primary" className="mt-2" />
                                    ) : (
                                        <tbody>
                                            {zoneWiseCalling !== null && zoneWiseCalling !== undefined && zoneWiseCalling?.length > 0
                                                ? zoneWiseCalling?.map((row, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{row?.zone}</td>
                                                            <td>{row?.callConnect}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{row?.callNotConnect}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{row?.dialCallConnects}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{row?.dialCallNotConnects}</td>
                                                            <td>{row?.totalCalls}</td>
                                                        </tr>
                                                    );
                                                })
                                                : "No calling data found"}
                                        </tbody>
                                    )}
                                </Table>
                            </div>
                        </div >
                    </Tab.Pane>

                    <Tab.Pane eventKey="UserWise">
                        {
                            userWiseCalling && userWiseCalling?.length > 0 &&
                            <Button className='btn-cancel mx-auto' onClick={() => generatePDF(userWiseTarget, UserOptions)}>
                                <Icon.ArrowDownCircle size={14} className='me-2' />Download UserWise PDF
                            </Button>
                        }

                        <div className="container tbl-container p-0 mt-4" ref={userWiseTarget}>
                            <div className="row tbl-fixed p-0">
                                <Table responsive="lg" hover>
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>Name</th>
                                            <th>Mobile</th>
                                            <th>Zone</th>
                                            <th>Gat</th>
                                            <th>Role</th>
                                            <th>Calling Count</th>
                                            <th>Talk Duration</th>
                                        </tr>
                                    </thead>

                                    {userLoader ? (
                                        <Spinner variant="primary" className="mt-2" />
                                    ) : (
                                        <tbody>
                                            {userWiseCalling !== null && userWiseCalling !== undefined && userWiseCalling.length > 0
                                                ? userWiseCalling.map((row, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{row?.name}</td>
                                                            <td>{row?.contactNo}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{row?.zonename + '-' + row?.zoneno}</td>
                                                            <td style={{ maxWidth: '150px', wordWrap: 'break-word' }}>{row?.gatname.map((row) => row).join(', ')}</td>
                                                            <td>{row?.usertype_name}</td>
                                                            <td>{row?.callingCount}</td>
                                                            <td>{row?.talkDuration}</td>
                                                        </tr>
                                                    );
                                                })
                                                : "No data found"}
                                        </tbody>
                                    )}
                                </Table>
                            </div>
                        </div>
                    </Tab.Pane>


                </Tab.Content>
            </Tab.Container>





            {/* <Row className='justify-content-center mt-3'>
        <Col md={3}>
          <Button variant="primary" onClick={()=>{setState(true); DownloadPdf();}}>
            Download as PDF
          </Button>
        </Col>
      </Row> */}
        </div>
    )
}

export default TotalCalling;
import React, { useEffect, useState } from 'react'
import { Container, Row, Spinner, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

const BillAnalysis = () => {

  useEffect(() => {
    getZoneWiseAnalysis();
  }, [])

  const [zoneWiseAnalysis, setzoneWiseAnalysis] = useState('');
  const [analysisLoader, setAnalysisLoader] = useState(false);

  const getZoneWiseAnalysis = async () => {
    setAnalysisLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/billdistribution/zonewiseanalysis', {
      method: 'GET'
    }).then((res) => res.json()).then((response) => {
      setzoneWiseAnalysis(response?.data);
      setAnalysisLoader(false);
    }).catch((err) => {
      console.log('Err at zoneWise Analysis', err);
      setAnalysisLoader(false);
    })
  }

  return (
    <div className='mt-2'>
      <Container>
        <div className='d-flex justify-content-center align-items-center mb-3'>
          <Icon.ReceiptCutoff className='me-2' size={22} color='#073c7c' />
          <h5 className='p-0 text-center mb-0'>Zone wise Bill Analysis</h5>
        </div>
        {
          analysisLoader ? 
          <center><Spinner /></center> :
          <Row>
            <div className="tbl-container p-0">
              <div className="tbl-fixed p-0" style={{ maxHeight: '80vh' }}>
                <Table responsive="lg" hover>
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th style={{ width: 'maxContent' }}>Zone</th>
                      <th>Till Yesterday</th>
                      <th>Today</th>
                      <th>Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    {zoneWiseAnalysis !== null && zoneWiseAnalysis !== undefined && zoneWiseAnalysis?.length > 0
                      ? zoneWiseAnalysis?.map((row, index) => {
                        return (
                          <tr style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{row?.zone}</td>
                            <td>{row?.tillYesterday}</td>
                            <td>{row?.today}</td>
                            <td>{row?.total}</td>
                          </tr>
                        );
                      })
                      : "No Bills data found"}
                  </tbody>
                </Table>
              </div>
            </div>
          </Row>
        }
      </Container>
    </div>
  )
}

export default BillAnalysis;
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Form, Nav, Row, Tab } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import MultibarChart from '../Charts/MultibarChart';
import GraphLoader from '../../Assets/GraphLoader.gif';
import { useSelector } from 'react-redux';

const CallSummary = () => {

  const [key, setKey] = useState("SingleStacked");
  const [date, setDate] = useState('');

  const financialYear = useSelector(state => state.activeData.activeMenu.activeTab)

  useEffect(() => {
    getCallSummary();
    getCombinedCallingGraph();
  }, [date, financialYear])

  const [summaryLabels, setSummaryLabels] = useState('');
  const [chartData, setChartData] = useState('');

  const [combinedCallingLabels, setCombinedCallingLabels] = useState('');
  const [combinedCallingData, setCombinedCallingData] = useState('');

  const getCallSummary = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + '/tc/graphcallingbyzone', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        callingDate: date,
        financialYear : financialYear
       })
    }).then((res) => res.json()).then((response) => {
      if (response.status === 200) {
        setSummaryLabels(response?.labels);

        let dataArray = [];

        dataArray.push({
          type: 'bar',
          label: "Call Connected",
          fill: false,
          data: response.callConnects,
          borderWidth: 2,
          borderColor: 'rgba(0, 218, 33, 1)',
          backgroundColor: "rgba(28, 255, 68, 0.43)"

        });

        dataArray.push({
          type: 'bar',
          label: "Call Not Connected",
          fill: false,
          data: response.callNotConnects,
          borderWidth: 2,
          borderColor: 'rgba(243, 0, 0, 1)',
          backgroundColor: "rgba(255, 0, 0, 0.4)"
        });

        setChartData(dataArray);
      }
      // if (response.data !== undefined && response.data.length > 0 && response.data !== null) {
      //   const zoneResult = response.data.map((item) => item.zonename);
      //   setSummaryZone(zoneResult);

      //   const countResult = response.data.map((item) => item.count);
      //   setSummaryCount(countResult);
      // }
      // else {
      //  console.log("Err while getting Call Summary");
      // }
    }).catch((err) => {
      console.log("Err while getting call summary", err);

    })
  }

  const getCombinedCallingGraph = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + '/tc/combinedCallingGraph', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        zonename: '',
        callingDate: date,
        financialYear : financialYear
       })
    }).then((res) => res.json()).then((response) => {
      if (response.status === 200) {
        setCombinedCallingLabels(response?.zonename);

        let dataArray = [];

        dataArray.push({
          type: 'bar',
          label: "Call Connected",
          fill: false,
          data: response.callConnect,
          borderWidth: 2,
          borderColor: 'rgba(0, 218, 33, 1)',
          backgroundColor: "rgba(28, 255, 68, 0.43)"

        });

        dataArray.push({
          type: 'bar',
          label: "Call Not Connected",
          fill: false,
          data: response.callNotConnect,
          borderWidth: 2,
          borderColor: 'rgba(243, 0, 0, 1)',
          backgroundColor: "rgba(255, 0, 0, 0.4)"
        });

        setCombinedCallingData(dataArray);
      }
    }).catch((err) => {
      console.log("Err while getting call summary", err);

    })
  }


  return (
    <div className='CallSummary'>
      <ToastContainer />
      <Container>
        <div className='d-flex justify-content-center align-items-center mt-2 mb-3'>
          <Icon.ChatText className='me-2' size={22} color='#073c7c' />
          <h5 className='mb-0'>Call Summary</h5>
        </div>
        <Row className='justify-content-center'>
          <Col md={12} className='tab'>
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
              <Row className="justify-content-between align-items-center">
                <Col md={2}>
                  <Form.Label>Filter by date</Form.Label>
                  <Form.Control type="date" max={new Date().toISOString().split('T')[0]} onChange={(e) => setDate(e.target.value)} />
                </Col>
                <Col md={4} className='d-flex justify-content-end p-0 mt-3 mt-md-0'>
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="SingleStacked">Merged Report</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="MultiBar">Seperate Report</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>


              <Tab.Content className='mt-4'>
                <Tab.Pane eventKey="SingleStacked">

                  {chartData !== null && chartData !== undefined && chartData.length > 0 ?
                    <MultibarChart chartLabels={summaryLabels} chartData={chartData} stacked={true} chartHeading="ZoneWise Call Merged Summary" /> :
                    <div className='d-flex justify-content-center align-items-center h-100'>
                      <img src={GraphLoader} alt="Loader" width={200} />
                    </div>
                  }

                  <div className='my-5'></div>

                  {combinedCallingData !== null && combinedCallingData !== undefined && combinedCallingData.length > 0 ?
                    <MultibarChart chartLabels={combinedCallingLabels} chartData={combinedCallingData} stacked={true} chartHeading="ZoneWise dialed + telecalling Summary" /> :
                    <div className='d-flex justify-content-center align-items-center h-100'>
                      <img src={GraphLoader} alt="Loader" width={200} />
                    </div>
                  }
                </Tab.Pane>

                <Tab.Pane eventKey="MultiBar">
                  {chartData !== null && chartData !== undefined && chartData.length > 0 ?
                    <MultibarChart chartLabels={summaryLabels} chartData={chartData} chartHeading="ZoneWise Call Seperate Summary" /> :
                    <div className='d-flex justify-content-center align-items-center h-100'>
                      <img src={GraphLoader} alt="Loader" width={200} />
                    </div>
                  }
                </Tab.Pane>

              </Tab.Content>


              <div className='my-5'></div>


            </Tab.Container>
          </Col>

        </Row>
      </Container>
    </div>
  )
}

export default CallSummary;
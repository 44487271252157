import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

const JaptiKarvai = () => {
  
  const financialYear = useSelector(state => state.activeData.activeMenu.activeTab)
  const [zone, setZone] = useState("");
  const [gat, setGat] = useState("");

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showJapti, setShowJapti] = useState(false);
  const [japtiDetails, setJaptiDetails] = useState();
  const [japtiPersonList, setJaptiPersonList] = useState([]);
  const [createdById, setCreatedById] = useState("");
  const [propertySearchId, setPropertySearchId] = useState("");
  const [isJaptiProcess, setIsJaptiProcess] = useState("");
  const [date, setDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Variables for Zone & Gat LIST ------------
  const [zoneList, setZoneList] = useState([]);
  const [allGatList, setAllGatList] = useState([]);

  //Varaibles for Filtering Gat depending on Zone ----------
  const [filterGat, setFilterGat] = useState([]);

  useEffect(() => {
    getZoneList();
    getGatList();
    getJaptiData();
    getJaptiKarvahiPersonList();
  }, [financialYear]);

  useEffect(() => {
    getFiltertedGat();
    setGat("");
  }, [zone]);

  const getFiltertedGat = () => {
    const result = allGatList?.filter((row) => row.zonekey == zone);
    setFilterGat(result);
  };

  // Function for Fetching ZONE LIST --------------------
  const getZoneList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/zoneData/getZoneData`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setZoneList(response.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  };

  // Function for Fetching GAT LIST --------------------
  const getGatList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/gatData/getGatData`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((response) => {
        setAllGatList(response.data);
        setFilterGat(response.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  };

  // Function for Fetching JAPTI KARVAHI PERSON LIST --------------------
  const getJaptiKarvahiPersonList = async () => {
    await fetch(
      process.env.REACT_APP_BASE_URL + `/japtikarvahi/getJaptiKarvahiPerson`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setJaptiPersonList(response);
      })
      .catch((err) => {
        console.log("Error While Notice Persons List", err);
      });
  };

  const getJaptiData = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/japtikarvahi/getAllJaptiKarvahi",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          zonekey: zone,
          gatkey: gat,
          createdById: createdById,
          propertySearchId: propertySearchId,
          isJaptiProcess: isJaptiProcess,
          japtiDate: date,
          startDate: startDate,
          endDate: endDate,
          financialYear : financialYear
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err while getting Japti Data", err);
        setLoader(false);
      });
  };

  const getSearchbyDates = () => {
    if(startDate && endDate){
      getJaptiData()
    }else{
      toast.error("Please Select both start and End Date")
    }
  }

  return (
    <div className="JaptiKarvai">
      <ToastContainer />
      <Container>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <Icon.Coin className="me-2" size={22} color="#073c7c" />
          <h5 className="p-0 text-center mb-0">Japti Karvai</h5>
        </div>

        <Row className="justify-content-center mb-4">
          <Col md={5} className="searchCard mb-4 mb-md-0">
            <div className="label mb-2 mx-auto">
              <p>Search by zone/gat</p>
            </div>
            <Row className="d-flex justify-content-center parentRow">
              <Col md={4} className="mb-3 mb-md-0">
                <Form.Select
                  value={zone}
                  onChange={(e) => setZone(e.target.value)}
                >
                  <option value="" selected disabled>
                    {" "}
                    Select Zone
                  </option>
                  {zoneList !== undefined && zoneList.length > 0 ? (
                    zoneList.map((row) => {
                      return (
                        <option value={row.zonekey}>
                          {row.zonename + "-" + row.zoneno}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
              </Col>

              <Col md={4} className="mb-3 mb-md-0">
                <Form.Select
                  value={gat}
                  onChange={(e) => setGat(e.target.value)}
                >
                  <option value="" selected disabled>
                    Select Gat
                  </option>
                  {filterGat !== undefined && filterGat.length > 0 ? (
                    filterGat.map((row) => {
                      return (
                        <option value={row?.gatkey}>
                          {row.mar_gatname + "-" + row.gatname}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
              </Col>

              <Col
                md={2}
                className="d-flex justify-content-md-start justify-content-center"
              >
                <Button className="btn btn-submit" onClick={getJaptiData}>
                  <Icon.Search className="me-2" size={14} />
                  Search
                </Button>
              </Col>
            </Row>
          </Col>

          <Col
            md={4}
            className="searchCard justify-content-center ms-0 ms-md-4"
          >
            <div className="label mb-2 mx-auto">
              <p>Search by created person</p>
            </div>
            <Row>
              <Col md={8}>
                <Form.Select
                  value={createdById}
                  onChange={(e) => setCreatedById(e.target.value)}
                >
                  <option value="" selected disabled>
                    Select japti person
                  </option>
                  {japtiPersonList?.japtiPersons !== undefined &&
                  japtiPersonList?.japtiPersons?.length > 0 ? (
                    japtiPersonList?.japtiPersons?.map((row) => {
                      return (
                        <option value={row?.createdById}>
                          {row?.createdByName + "-" + row?.createdByMobile}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
              </Col>
              <Col
                md={2}
                className="d-flex justify-content-md-start justify-content-center mt-3 mt-md-0"
              >
                <Button className="btn btn-submit" onClick={getJaptiData}>
                  <Icon.Search className="me-2" size={14} />
                  Search
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={4} className="searchCard justify-content-center mb-4 ">
            <div className="label mb-2 mx-auto">
              <p>Search by Property Search Person</p>
            </div>
            <Row>
              <Col md={8}>
                <Form.Select
                  value={propertySearchId}
                  onChange={(e) => setPropertySearchId(e.target.value)}
                >
                  <option value="" selected disabled>
                    Select Property Search Person
                  </option>
                  {japtiPersonList?.propertySearchPersons !== undefined &&
                  japtiPersonList.propertySearchPersons?.length > 0 ? (
                    japtiPersonList.propertySearchPersons?.map((row) => {
                      return (
                        <option value={row?.propertySearchId}>
                          {row?.propertySearchName +
                            "-" +
                            row?.propertySearchMobile}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
              </Col>
              <Col
                md={2}
                className="d-flex justify-content-md-start justify-content-center mt-3 mt-md-0"
              >
                <Button className="btn btn-submit" onClick={getJaptiData}>
                  <Icon.Search className="me-2" size={14} />
                  Search
                </Button>
              </Col>
            </Row>
          </Col>

          <Col
            md={5}
            className="searchCard justify-content-center mb-4 ms-0 ms-md-4"
          >
            <div className="label mb-2 mx-auto">
              <p>Search by date</p>
            </div>
            <Row>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="me-3">
                  <Form.Label>Search by Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) =>
                      setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </div>
                <div className="me-3">
                  <Form.Label>Search by End Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    min={startDate ? startDate : ""}
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) =>
                      setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </div>
                <div className="d-flex justify-content-center align-items-end">
                  <Button
                    className="btn btn-submit"
                    onClick={ () => getSearchbyDates() }
                    style={{ height: 40 }}
                  >
                    <Icon.Search className="me-2" size={14} />
                    Search
                  </Button>
                </div>
              </div>
              {/* <Form.Control type='date' value={date} max={new Date().toISOString().split("T")[0]}
                    onChange={(e) => setDate(moment(e.target.value).format('YYYY-MM-DD'))}/> */}
              <Col
                md={4}
                className="d-flex justify-content-md-start justify-content-center mt-3 mt-md-0"
              ></Col>
            </Row>
          </Col>

          {/* <Col md={4} className="searchCard justify-content-center mb-4 ms-0 ms-md-4">
            <div className="label mb-2 mx-auto"><p>Search by japti process status</p></div>
            <Row>
              <Col md={8}>
               
            <Form.Select  value={isJaptiProcess} onChange={(e) => setIsJaptiProcess(e.target.value)}>
                  <option value="" selected disabled>
                    Select Property Search Person
                  </option>
                  <option value={true}>Japti Karvahi Done</option>
                  <option value={false}>Japti karvahi InProcess</option>
                </Form.Select>
                 
                </Col>
                    <Col md={2} className="d-flex justify-content-md-start justify-content-center">
                    <Button className="btn btn-submit" onClick={getJaptiData}>
                      <Icon.Search className="me-2" size={14} />Search
                    </Button>
                  </Col>
                 
                 
                </Row>
         </Col>  */}
        </Row>

        <Row>
          <h5 className="text-center">
            Total count - {data?.japtiKarvahiCount ? data?.japtiKarvahiCount : 0}
          </h5>
          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: "65vh" }}>
              <Table responsive="lg" hover>
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Property Code</th>
                    <th>Property Name</th>
                    <th>Zone</th>
                    <th>Gat</th>
                    <th>Witness Count</th>
                    <th>Property Search Person</th>
                    <th>Japti Person</th>
                    <th>Japti Date</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {loader ? (
                  <Spinner variant="primary" className="mt-2" />
                ) : (
                  <tbody>
                    {data?.data !== null &&
                    data?.data !== undefined &&
                    data?.data.length > 0
                      ? data?.data.map((row, index) => {
                          return (
                            <tr
                              style={{ textAlign: "center", cursor: "pointer" }}
                            >
                              <td>{index + 1}</td>
                              <td>{row?.propertyCode}</td>
                              <td style={{ width: "400px" }}>
                                {row?.propertyName}
                              </td>
                              <td>{row?.zonename}</td>
                              <td>{row?.gatname.toString()}</td>
                              <td>
                                {row?.witnessCount ? row?.witnessCount : "-"}
                              </td>
                              <td>
                                {row?.propertySearchName
                                  ? row?.propertySearchName +
                                    `${
                                      row?.propertySearchMobile
                                        ? `(` + row?.propertySearchMobile + `)`
                                        : ""
                                    }`
                                  : "-"}
                              </td>
                              <td>
                                {row?.createdByName
                                  ? row?.createdByName +
                                    `${
                                      row?.createdByMobile
                                        ? `(` + row?.createdByMobile + `)`
                                        : ""
                                    }`
                                  : "-"}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {row?.japtiDate
                                  ? moment(row?.japtiDate).format("DD-MM-YYYY")
                                  : "-"}
                              </td>
                              <td>
                                <Icon.Eye
                                  size={20}
                                  onClick={() => {
                                    setJaptiDetails(row);
                                    setShowJapti(true);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })
                      : "No users data found"}
                  </tbody>
                )}
              </Table>
            </div>
          </div>
        </Row>

        {/* --------- Japti Details View Modal --------- */}

        <Modal
          dialogClassName="modal"
          show={showJapti}
          onHide={() => setShowJapti(false)}
          centered
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              <Icon.Coin className="me-2" />
              <h5 className="mb-0">View Japti Details</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col md={5}>
                <div md={3} className="data">
                  <h6>
                    {japtiDetails?.isJaptiProcess ? (
                      <Icon.ToggleOn className="me-2" size={16} />
                    ) : (
                      <Icon.ToggleOff className="me-2" size={16} />
                    )}
                    Japti Status :{" "}
                  </h6>
                  <p>
                    {japtiDetails?.isJaptiProcess ? (
                      <Icon.CheckCircleFill color="green" />
                    ) : (
                      <Icon.XCircleFill color="red" />
                    )}
                  </p>
                </div>

                <div md={2} className="data">
                  <h6>
                    {japtiDetails?.isMukti ? (
                      <Icon.ToggleOn className="me-2" size={16} />
                    ) : (
                      <Icon.ToggleOff className="me-2" size={16} />
                    )}
                    Mukti Status :{" "}
                  </h6>
                  <p>
                    {japtiDetails?.isMukti ? (
                      <Icon.CheckCircleFill color="green" />
                    ) : (
                      <Icon.XCircleFill color="red" />
                    )}
                  </p>
                </div>

                <div md={2} className="data">
                  <h6>
                    <Icon.PersonFill className="me-2" />
                    Witness :{" "}
                  </h6>
                  <p>{japtiDetails?.witnessCount}</p>
                </div>

                <div md={2} className="data">
                  <h6>
                    <Icon.ClockFill className="me-2" />
                    Japti Date :{" "}
                  </h6>
                  <p>{moment(japtiDetails?.japtiDate).format("YYYY-MM-DD")}</p>
                </div>

                <div md={2} className="data">
                  <h6>
                    <Icon.ClockFill className="me-2" />
                    Visited By :{" "}
                  </h6>
                  <p>
                    {japtiDetails?.createdByName
                      ? japtiDetails?.createdByName
                      : "" +
                        `${
                          japtiDetails?.createdByMobile
                            ? `(` + japtiDetails?.createdByMobile + `)`
                            : ""
                        }`}
                  </p>
                </div>
              </Col>

              <Col md={7}>
                <div className="data">
                  <h6>
                    <Icon.BuildingFill className="me-2" />
                    Property Code :
                  </h6>
                  <p>{japtiDetails?.propertyCode}</p>
                </div>

                <div className="data">
                  <h6 style={{ whiteSpace: "nowrap" }}>
                    <Icon.PersonFill className="me-2" />
                    Property Name :
                  </h6>
                  <p>{japtiDetails?.propertyName}</p>
                </div>

                <div className="data">
                  <h6>
                    <Icon.Truck className="me-2" />
                    Japti Property :
                  </h6>
                  <p>
                    {japtiDetails?.japtiProperty?.map((row) => row).join(", ")}
                  </p>
                </div>

                <div md={3} className="data">
                  <h6>
                    <Icon.GeoFill className="me-2" />
                    Zone :
                  </h6>
                  <p>{japtiDetails?.zonename}</p>
                </div>

                <div md={3} className="data">
                  <h6>
                    <Icon.GeoFill className="me-2" />
                    Gat :
                  </h6>
                  <p>{japtiDetails?.gatname}</p>
                </div>
              </Col>
            </Row>

            <Row>
              <div className="data">
                <h6>
                  <Icon.GeoAltFill className="me-2" />
                  Address :
                </h6>
                <p>{japtiDetails?.propertyAddress}</p>
              </div>

              <Row>
                {japtiDetails?.japtiDocuments?.length > 0 ? (
                  <Col md={4}>
                    <h6>
                      <Icon.Coin className="me-2" color="#073c7c" />
                      Japti Documents :
                    </h6>
                    <div className="data ms-5">
                      {japtiDetails?.japtiDocuments?.map((mediaUrl) => {
                        const isImage =
                          mediaUrl.endsWith(".jpg") ||
                          mediaUrl.endsWith(".jpeg") ||
                          mediaUrl.endsWith(".png");
                        const isVideo =
                          mediaUrl.endsWith(".mp4") ||
                          mediaUrl.endsWith(".mov") ||
                          mediaUrl.endsWith(".avi");

                        return (
                          <a href={mediaUrl} target="_blank" className="me-3">
                            {isImage && <Icon.Image size={30} />}
                            {isVideo && <Icon.CameraVideo size={30} />}
                          </a>
                        );
                      })}
                    </div>
                  </Col>
                ) : (
                  ""
                )}

                {japtiDetails?.japtiWarrant?.length > 0 ? (
                  <Col md={4}>
                    <h6>
                      <Icon.CardHeading className="me-2" color="#073c7c" />
                      Japti Warrant :
                    </h6>
                    <div className="data ms-5">
                      {japtiDetails?.japtiWarrant?.map((mediaUrl) => {
                        const isImage =
                          mediaUrl.endsWith(".jpg") ||
                          mediaUrl.endsWith(".jpeg") ||
                          mediaUrl.endsWith(".png");
                        const isVideo =
                          mediaUrl.endsWith(".mp4") ||
                          mediaUrl.endsWith(".mov") ||
                          mediaUrl.endsWith(".avi");

                        return (
                          <a href={mediaUrl} target="_blank" className="me-3">
                            {isImage && <Icon.Image size={30} />}
                            {isVideo && <Icon.CameraVideo size={30} />}
                          </a>
                        );
                      })}
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default JaptiKarvai;

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner, Tab, Table, Nav, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import MultibarChart from '../Charts/MultibarChart';
import GraphLoader from '../../Assets/GraphLoader.gif';
import Chartjs from '../Charts/Chartjs';
import moment from 'moment';
import { useSelector } from 'react-redux';

const DialedCallLog = () => {
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const [date, setDate] = useState('');
    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    const [key, setKey] = useState("LogData");

    const financialYear = useSelector(state => state.activeData.activeMenu.activeTab)

    const [dailCallingLabels, setDailCallingLabels] = useState('');
    const [dailCallingData, setDailCallingData] = useState('');

    const [dailTalkDurationLabels, setDailTalkDurationLabels] = useState('');
    const [dailTalkDurationData, setTalkDurationData] = useState('');

    
    const [users, setUsers] = useState('');
    const [totalCallCount, setTotalCallCount] = useState('')
    const [totalUsersCount, setTotalUsersCount] = useState('')

    const [zone, setZone] = useState('');
    const [zoneList, setZoneList] = useState('');

    useEffect(()=>{
      getZone();
    },[])

    useEffect(() => {
      getAllUser();
      getDailCallingByZone();
      getTalkDurationByZone();
    }, [date, zone, startDate, endDate, financialYear])
  
    const getZone = async () => {
      await fetch(process.env.REACT_APP_BASE_URL + '/zoneData/getZoneData', {
        method: "GET"
      }).then((res) => res.json()).then((response) => {
        setZoneList(response.data);
      }).catch((err) => {
        console.log("err while gettting zone=>", err);
      })
    }

    const getAllUser = async () => {
      setLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + '/user/getDialCallingUsers', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          zonename:zone, 
          callingDate: date , 
          startDate: startDate,
          endDate:endDate,
          financialYear : financialYear
         })
      }).then((res) => res.json()).then((response) => {
        setUsers(response.data);
        setTotalCallCount(response?.totalCallCount);
        setTotalUsersCount(response?.userCount);
        setLoader(false);
      }).catch((err) => {
        console.log("err while gettting users=>", err);
      })
    }

    const getDailCallingByZone = async () => {
      await fetch(process.env.REACT_APP_BASE_URL + '/dialCall/graphCallingByZone', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          callingDate:date,
          financialYear : financialYear })
      }).then((res) => res.json()).then((response) => {
        if (response.status === 200) {
          setDailCallingLabels(response?.zonename);

          let dataArray = [];
  
          dataArray.push({
            type: 'bar',
            label: "Call Connected",
            fill: false,
            data: response.callConnect,
            borderWidth: 2,
            borderColor: 'rgba(0, 218, 33, 1)',
            backgroundColor: "rgba(28, 255, 68, 0.43)"
  
          });
  
          dataArray.push({
            type: 'bar',
            label: "Call Not Connected",
            fill: false,
            data: response.callNotConnect,
            borderWidth: 2,
            borderColor: 'rgba(243, 0, 0, 1)',
            backgroundColor: "rgba(255, 0, 0, 0.4)"
          });
  
          setDailCallingData(dataArray);
        }
      }).catch((err) => {
        console.log("Err while getting call summary", err);
  
      })
    }

    const getTalkDurationByZone = async () => {
      await fetch(process.env.REACT_APP_BASE_URL + '/dialCall/graphTalkDurationByZone', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          zonename: zone,
          callingDate: date,
          financialYear : financialYear })
      }).then((res) => res.json()).then((response) => {
        if (response.status === 200) {
          setDailTalkDurationLabels(response?.zone);

          let dataArray = [];
  
          dataArray.push({
            type: 'bar',
            label: "Talk Duration in Hrs",
            fill: false,
            data: response.talk_duration,
            borderWidth: 2,
            borderColor: 'rgba(0, 218, 33, 1)',
            backgroundColor: "rgba(28, 255, 68, 0.43)"
          });
  
          setTalkDurationData(dataArray);
        }
      }).catch((err) => {
          console.log("Err while getting call summary", err);
      })
    }

    return (
      <div className='DialedCallLog'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={12} className='tab'>
              <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                <Row className="justify-content-between align-items-center">

                <Col md={7} className='d-flex mb-3 mb-md-0'>
                  <div className='me-3'>
                    <Form.Label>Search by Start Date</Form.Label>
                    <Form.Control type="date" value={startDate} max={new Date().toISOString().split("T")[0]} onChange={(e) => setStartDate(moment(e.target.value).format("YYYY-MM-DD"))} />
                  </div>
                  <div className='me-3'>
                    <Form.Label>Search by End Date</Form.Label>
                    <Form.Control type="date" value={endDate} min={startDate ? startDate : ""} max={new Date().toISOString().split("T")[0]} onChange={(e) => setEndDate(moment(e.target.value).format("YYYY-MM-DD"))} />
                  </div>
                  {
                    key === "LogData" &&
                    <div>
                    <Form.Label>Search by zone</Form.Label>
                    <Form.Select onChange={(e) => setZone(e.target.value)}>
                      <option value="" selected disabled>Select zone</option>
                      {
                        zoneList !== undefined && zoneList.length > 0 && zoneList !== null ?
                          zoneList?.map((row) => {
                            return (
                              <option value={row?.zonename}>{row?.zonename + -row?.zoneno}</option>
                            )
                          }) : <option value=""></option> 
                      }
                    </Form.Select>
                  </div>
                  }
                
                  
                  </Col>

                  <Col md={4} className='d-flex justify-content-end p-0'>
                      <Nav variant="pills" className="flex-row">
                        <Nav.Item>
                          <Nav.Link eventKey="LogData">Log's Data</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="LogReport">Log's Report</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>

                    <Tab.Content>
                      <Tab.Pane eventKey="LogData">
                      <Row>
                      <div className='d-flex justify-content-center align-items-center mt-2 mb-1'>
                        <Icon.TelephoneForward className='me-2' size={20} color='#073c7c' />
                        <h5 className='mb-0'>Dialed Call Log {(zone && 'of '+ zone) + (date && ' at ' + moment(date).format('DD-MM-YYYY'))}</h5>
                      </div>

                          <div className='d-flex justify-content-center align-items-center mt-2 mb-1'>
                            <h6 className='mb-0' style={{ marginRight: '20px' }}>
                              Total users :<strong>{totalUsersCount ? totalUsersCount : 0}</strong>
                            </h6>

                            <h6 className='mb-0' >
                              Total calls : <strong>{totalCallCount ? totalCallCount : 0}</strong>
                            </h6>
                          </div>
                      </Row>
    
              <Row className='mt-3 mb-5'>
                <div className="container tbl-container p-0">
                  <div className="row tbl-fixed p-0" style={{ maxHeight: '72vh' }}>
                    <Table responsive="lg" hover>
                      <thead>
                        <tr>
                          <th>Sr.</th>
                          <th style={{ width: 'maxContent' }}>Name</th>
                          <th>Mobile No.</th>
                          <th>Zone</th>
                          <th> Gat</th>
                          <th>Role</th>
                          <th style={{ whiteSpace: 'nowrap' }}>Calling Count</th>
                          <th>Talk Duration</th>
                          <th>Action</th>
                        </tr>
                      </thead>
      
                      {loader ? (
                        <Spinner variant="primary" className="mt-2" />
                      ) : (
                        <tbody>
                          {users !== null && users !== undefined && users.length > 0
                            ? users.map((row, index) => {
                              return (
                                <tr 
                                  style={{ textAlign: "center", cursor: "pointer" }}>
                                  <td>{index + 1}</td>
                                  <td style={{ whiteSpace: 'nowrap' }}>{row?.name}</td>
                                  <td>{row?.contactNo}</td>
                                  <td style={{ whiteSpace: 'nowrap' }}>{row?.zonename}</td>
                                  <td style={{ maxWidth: '150px', wordWrap: 'break-word' }}>{row?.gatname.toString()}</td>
                                  <td style={{ whiteSpace: 'nowrap' }}>{row?.usertype_name}</td>
                                  <td>{row?.callingCount}</td>
                                  <td style={{ whiteSpace: 'nowrap' }}>{row?.talkDuration}</td>
                                  <td onClick={() => navigate("/dashboard/dialed-call-log-report", { state: row })}><Icon.Eye size={22} /></td>
                                </tr>
                              );
                            })
                            : "No call logs found"}
                        </tbody>
                      )}
                    </Table>
                  </div>
                </div>
      
              </Row>
                      </Tab.Pane>

            <Tab.Pane eventKey="LogReport">
              <div className='my-4'></div>
              {
                dailCallingData !== null && dailCallingData !== undefined && dailCallingData?.length > 0 ?
                    <Chartjs chartLabels={dailCallingLabels} chartData={dailCallingData}  stacked={false}
                      chartHeading="Zonewise dailed calling" height='350px' isPlugin={false} isModal={true}/> :
                        <div className='d-flex justify-content-center align-items-center h-100'>
                          <img src={GraphLoader} alt="Loader" width={200} />
                        </div>
                }
                    <div className='my-5'></div>
              {
                dailTalkDurationData !== null && dailTalkDurationData !== undefined && dailTalkDurationData?.length > 0 ?
                    <Chartjs chartLabels={dailTalkDurationLabels} chartData={dailTalkDurationData}  stacked={false}
                      chartHeading="Zonewise dailed talk Duration" height='350px' isPlugin={false} isModal={true}/> :
                        <div className='d-flex justify-content-center align-items-center h-100'>
                          <img src={GraphLoader} alt="Loader" width={200} />
                        </div>
                }

                  <div className='my-5'></div>

              </Tab.Pane>
              </Tab.Content>
                </Row>



              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </div>
    )
}

export default DialedCallLog

import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';
import { useSelector } from 'react-redux';

const JaptiMukti = () => {
  
  const financialYear = useSelector(state => state.activeData.activeMenu.activeTab)
  const userState = useSelector(state => state.loginData.userData);
  const [zone, setZone] = useState('');
  const [gat, setGat] = useState('');
  const [japtiDate, setJaptiDate] = useState();

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [muktiDetails, setMuktiDetails] = useState();
  const [muktiPersonList, setMuktiPersonList] = useState([]);
  const [muktiPersonId, setMuktiPersonId] = useState('');
  const [propertySearchId, setPropertySearchId] = useState('');

  // Variables for Zone & Gat LIST ------------
  const [zoneList, setZoneList] = useState([]);
  const [allGatList, setAllGatList] = useState([]);

  //Varaibles for Filtering Gat depending on Zone ----------
  const [filterGat, setFilterGat] = useState([]);

  useEffect(() => {
    getZoneList()
    getGatList();
    getJaptiData();
    getJaptiMuktiPersonList();
  }, [financialYear])

  useEffect(() => {
    getFiltertedGat();
    setGat("");
  }, [zone]);

  const getFiltertedGat = () => {
    const result = allGatList !== undefined && allGatList.length > 0
                    ? allGatList.filter((row) => row.zonekey == zone): "";
    setFilterGat(result);
  };

  // Function for Fetching ZONE LIST --------------------
  const getZoneList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/zoneData/getZoneData`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setZoneList(response.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  };


  // Function for Fetching GAT LIST --------------------
  const getGatList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/gatData/getGatData`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((response) => {
        setAllGatList(response.data);
        setFilterGat(response.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  };


  // Function for Fetching JAPTI MUKTI PERSON LIST --------------------
  const getJaptiMuktiPersonList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/mukti/getMuktiPersons`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setMuktiPersonList(response);
      })
      .catch((err) => {
        console.log("Error While Notice Persons List", err);
      });
  };

  const getJaptiData = async () => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/mukti/getAllMukti', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        zonekey: zone,
        gatkey: gat,
        muktiPersonId: muktiPersonId,
        propertySearchId: propertySearchId,
        financialYear : financialYear
      })
    }).then((res) => res.json()).then((response) => {
      setData(response);
      setLoader(false);
    }).catch((err) => {
      console.log("Err while getting Japti Data", err);
      setLoader(false);
    })
  }


  return (
    <div className='JaptiKarvai'>
     
      <Container>

        <div className='d-flex justify-content-center align-items-center mb-4'>
          <Icon.CardHeading className='me-2' size={22} color='#073c7c' />
          <h5 className='p-0 text-center mb-0'>Japti Mukti</h5>
        </div>

        <Row className="justify-content-center mb-4">
          <Col md={6} className="searchCard">
            <div className="label mb-2 mx-auto"><p>Search by zone/gat</p></div>
            <Row className="d-flex justify-content-center">
              <Col md={4} className="mb-3 mb-md-0">
                <Form.Select value={zone} onChange={(e) => setZone(e.target.value)}>
                  <option value="" selected disabled> Select Zone</option>
                  {zoneList !== undefined && zoneList.length > 0 ? (
                    zoneList.map((row) => {
                      return (
                        <option value={row.zonekey}>{row.zonename + "-" + row.zoneno}</option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
              </Col>

              <Col md={4} className="mb-3 mb-md-0">
                <Form.Select value={gat} onChange={(e) => setGat(e.target.value)}>
                  <option value="" selected disabled>
                    Select Gat
                  </option>
                  {filterGat !== undefined && filterGat.length > 0 ? (
                    filterGat.map((row) => {
                      return (
                        <option value={row?.gatkey}>{row.mar_gatname + '-' + row.gatname}</option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
              </Col>

              <Col md={2} className="d-flex justify-content-md-start justify-content-center">
                <Button className="btn btn-submit" onClick={getJaptiData}>
                  <Icon.Search className="me-2" size={14} />Search
                </Button>
              </Col>
            </Row>

          </Col>

          <Col md={4} className="searchCard justify-content-center ms-0 ms-md-4">
            <div className="label mb-2 mx-auto"><p>Search by mukti person</p></div>
            <Row>
              <Col md={8}>
                <Form.Select value={muktiPersonId} onChange={(e) => setMuktiPersonId(e.target.value)}>
                  <option value="" selected disabled>
                    Select any mukti person
                  </option>
                  {muktiPersonList?.muktiPersons !== undefined && muktiPersonList?.muktiPersons?.length > 0 ? (
                    muktiPersonList?.muktiPersons?.map((row) => {
                      return (
                        <option value={row?.muktiPersonId}>{row?.muktiPersonName + '-' + row?.muktiPersonMobile}</option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>

              </Col>
              <Col md={2} className="d-flex justify-content-md-start justify-content-center">
                <Button className="btn btn-submit" onClick={getJaptiData}>
                  <Icon.Search className="me-2" size={14} />Search
                </Button>
              </Col>

            </Row>
          </Col>

        </Row>

        <Row className='justify-content-center'>
          <Col md={4} className="searchCard justify-content-center mb-4">
            <div className="label mb-2 mx-auto"><p>Search by Property Search Person</p></div>
            <Row>
              <Col md={8}>
                <Form.Select value={propertySearchId} onChange={(e) => setPropertySearchId(e.target.value)}>
                  <option value="" selected disabled>
                    Select Property Search Person
                  </option>
                  {muktiPersonList?.propertySearchPersons !== undefined && muktiPersonList?.propertySearchPersons?.length > 0 ? (
                    muktiPersonList?.propertySearchPersons?.map((row) => {
                      return (
                        <option value={row?.propertySearchId}>{row?.propertySearchName + '-' + row?.propertySearchMobile}</option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>

              </Col>
              <Col md={2} className="d-flex justify-content-md-start justify-content-center">
                <Button className="btn btn-submit" onClick={getJaptiData}>
                  <Icon.Search className="me-2" size={14} />Search
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <h5 className='text-center'>Total count - {data?.muktiCount}</h5>
          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: '65vh' }}>
              <Table responsive="lg" hover>
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Property Code</th>
                    <th>Property Name</th>
                    <th>Zone</th>
                    <th>Gat</th>
                    <th>Visit Date</th>
                    <th>Notice Date</th>
                    <th>Property Search Person</th>
                    <th>Mukti Done By</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {loader ? (
                  <Spinner variant="primary" className="mt-2" />
                ) : (
                  <tbody>
                    {data?.data !== null && data?.data !== undefined && data?.data.length > 0
                      ? data?.data.map((row, index) => {
                        return (
                          <tr style={{ textAlign: "center", cursor: "pointer" }}>
                            <td>{index + 1}</td>
                            <td>{row?.propertyCode}</td>
                            <td style={{}}>{row?.propertyName}</td>
                            <td>{row?.zonename}</td>
                            <td>{row?.gatname.toString()}</td>
                            <td>{moment(row?.visitDate).format("DD-MM-YYYY")}</td>
                            <td>{row?.japtiNoticeDate ? moment(row?.japtiNoticeDate).format("DD-MM-YYYY") : '-'}</td>
                            <td>{row?.propertySearchName ? row?.propertySearchName + `${row?.propertySearchMobile ? `(` + row?.propertySearchMobile + `)` : ''}` : '-'}</td>
                            <td>{row?.muktiPersonName ? row?.muktiPersonName + `${row?.muktiPersonMobile ? `(` + row?.muktiPersonMobile + `)` : ''}` : '-'}</td>
                            <td><Icon.Eye size={20} onClick={() => { setMuktiDetails(row); setShowNotice(true); }} /></td>
                          </tr>
                        );
                      })
                      : "No users data found"}
                  </tbody>
                )}
              </Table>
            </div>
          </div>

        </Row>


        {/* --------- Japti Details View Modal --------- */}

        <Modal dialogClassName="modal" show={showNotice} onHide={() => setShowNotice(false)} centered backdrop="static" keyboard={false} size="lg" >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              <Icon.Coin className='me-2' />
              <h5 className="mb-0">View Japti Notice Details</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col md={6}>
                <div className='data'>
                  <h6>{muktiDetails?.isJaptiProcess ? <Icon.ToggleOn className='me-2' size={16} /> :
                    <Icon.ToggleOff className='me-2' size={16} />}Japti Status : </h6>
                  <p>{muktiDetails?.isJaptiProcess ? <Icon.CheckCircleFill color='green' /> :
                    <Icon.XCircleFill color='red' />}</p>
                </div>

                <div md={2} className='data'>
                  <h6>{muktiDetails?.isMukti ? <Icon.ToggleOn className='me-2' size={16} /> :
                    <Icon.ToggleOff className='me-2' size={16} />}Mukti Status : </h6>
                  <p>{muktiDetails?.isMukti ? <Icon.CheckCircleFill color='green' /> :
                    <Icon.XCircleFill color='red' />}</p>
                </div>

                <div className='data'>
                  <h6><Icon.ClockFill className='me-2' />Japti Date : </h6>
                  <p>{muktiDetails?.japtiDate ?
                    moment(muktiDetails?.japtiDate).format("YYYY-MM-DD") : '-'}</p>
                </div>

                <div className='data'>
                  <h6><Icon.PersonFill className='me-2' />Mukti Done By : </h6>
                  <p>{muktiDetails?.muktiPersonName && muktiDetails?.muktiPersonMobile ?
                    muktiDetails?.muktiPersonName ? muktiDetails?.muktiPersonName : '' + `${muktiDetails?.muktiPersonMobile ? `(` + muktiDetails?.muktiPersonMobile + `)` : ''}` : '-'}</p>
                </div>

                <div className='data'>
                  <h6><Icon.QuestionCircleFill className='me-2' />Mukti Reason : </h6>
                  <p>{muktiDetails?.muktiReason}</p>

                </div>

              </Col>

              <Col md={6}>
                <div className='data'>
                  <h6><Icon.BuildingFill className='me-2' />Property Code :</h6>
                  <p>{muktiDetails?.propertyCode}</p>
                </div>

                <div className='data'>
                  <h6><Icon.PersonFill className='me-2' />Property Name :</h6>
                  <p>{muktiDetails?.propertyName}</p>
                </div>

                <div className='data'>
                  <h6><Icon.Truck className='me-2' />Japti Property :</h6>
                  <p>{muktiDetails?.japtiProperty?.map((row) => row).join(', ')}</p>
                </div>

                <div md={3} className='data'>
                  <h6><Icon.GeoFill className='me-2' />Zone :</h6>
                  <p>{muktiDetails?.zonename}</p>
                </div>

                <div md={3} className='data'>
                  <h6><Icon.GeoFill className='me-2' />Gat :</h6>
                  <p>{muktiDetails?.gatname}</p>
                </div>
              </Col>

            </Row>

            <Row>

              <div className='data'>
                <h6><Icon.GeoAltFill className='me-2' />Address :</h6>
                <p>{muktiDetails?.propertyAddress}</p>
              </div>

              <Row>
                <Col md={4}>
                  <h6><Icon.CardHeading className='me-2' color='#073c7c' />Mukti Images :</h6>
                  <div className='data ms-5'>
                    {
                      muktiDetails?.muktiImages?.map((mediaUrl) => {

                        const isImage = mediaUrl.endsWith('.jpg') || mediaUrl.endsWith('.jpeg') || mediaUrl.endsWith('.png');
                        const isVideo = mediaUrl.endsWith('.mp4') || mediaUrl.endsWith('.mov') || mediaUrl.endsWith('.avi');

                        return (
                          <a href={mediaUrl} target='_blank' className='me-3'>
                            {isImage && <Icon.Image size={30} />}
                            {isVideo && <Icon.CameraVideo size={30} />}
                          </a>
                        )
                      })
                    }
                  </div>
                </Col>
                <Col md={4}>
                  <h6><Icon.Coin className='me-2' color='#073c7c' />Japti Documents :</h6>
                  <div className='data ms-5'>
                    {
                      muktiDetails?.japtiDocuments?.map((mediaUrl) => {

                        const isImage = mediaUrl.endsWith('.jpg') || mediaUrl.endsWith('.jpeg') || mediaUrl.endsWith('.png');
                        const isVideo = mediaUrl.endsWith('.mp4') || mediaUrl.endsWith('.mov') || mediaUrl.endsWith('.avi');

                        return (

                          <a href={mediaUrl} target='_blank' className='me-3'>
                            {isImage && <Icon.Image size={30} />}
                            {isVideo && <Icon.CameraVideo size={30} />}
                          </a>
                        )
                      })
                    }
                  </div>
                </Col>

                <Col md={4}>
                  <h6><Icon.CardHeading className='me-2' color='#073c7c' />Japti Warrant :</h6>
                  <div className='data ms-5'>
                    {
                      muktiDetails?.japtiWarrant?.map((mediaUrl) => {

                        const isImage = mediaUrl.endsWith('.jpg') || mediaUrl.endsWith('.jpeg') || mediaUrl.endsWith('.png');
                        const isVideo = mediaUrl.endsWith('.mp4') || mediaUrl.endsWith('.mov') || mediaUrl.endsWith('.avi');

                        return (

                          <a href={mediaUrl} target='_blank' className='me-3'>
                            {isImage && <Icon.Image size={30} />}
                            {isVideo && <Icon.CameraVideo size={30} />}
                          </a>
                        )
                      })
                    }
                  </div>
                </Col>
              </Row>

            </Row>
          </Modal.Body>


        </Modal>
      </Container>
    </div>
  )
}

export default JaptiMukti;
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
  Card,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import BarChart from "../Charts/BarChart";
import GraphLoader from "../../Assets/GraphLoader.gif";
import { useSelector } from "react-redux";

const CallLog = () => {
  const [loader, setLoader] = useState(false);
  const [callLoader, setCallLoader] = useState(false);
  const [zoneLabels, setZoneLabels] = useState("");
  const [hours, setHours] = useState("");
  const navigate = useNavigate();
  const [zoneList, setZoneList] = useState("");
  const [zone, setZone] = useState("");
  const [date, setDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const financialYear = useSelector(state => state.activeData.activeMenu.activeTab)

  useEffect(() => {
    getZone();
    graphTalkDurationByZone();
  }, []);

  useEffect(() => {
    getAllUser();
  }, [zone, date, startDate, endDate, financialYear]);

  const getZone = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/zoneData/getZoneData", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setZoneList(response.data);
      })
      .catch((err) => {
        console.log("err while gettting zone=>", err);
      });
  };

  const graphTalkDurationByZone = async () => {
    setCallLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/tc/graphTalkDurationByZone",
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setZoneLabels(response?.zone);
        setHours(response?.talk_duration);
        setCallLoader(false);
      })
      .catch((Err) => {
        console.log("Err while getting graphTalkDurationByZone", Err);
        setCallLoader(false);
      });
  };

  const [users, setUsers] = useState("");
  const [totalCallCount, setTotalCallCount] = useState("");
  const [totalUsersCount, setTotalUsersCount] = useState("");

  const getAllUser = async (val) => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + "/user/getTeleCallingUsers", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        zonekey: zone,
        callingDate: date,
        startDate: startDate,
        endDate: endDate,
        financialYear : financialYear
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setUsers(response.data);
        setTotalCallCount(response?.totalCallCount);
        setTotalUsersCount(response?.userCount);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err while gettting users=>", err);
      });
  };

  return (
    <div>
      <Container>
        <div className="d-flex justify-content-center align-items-center mt-2 mb-1">
          <Icon.TelephoneForward className="me-2" size={22} color="#073c7c" />
          <h5 className="mb-0">Call Logs</h5>
        </div>

        <Row className="mb-3">
          <Col
            md={12}
            className="my-2 d-flex justify-content-center align-items-center"
          >
            <Card
              className="ChartCard"
              style={{
                height: "280px",
                maxHeight: "280px",
                width: "100%",
                padding: "20px 10px 12px 0px",
              }}
            >
              <h6 className="mb-0 mx-3">Talk durations by zone</h6>
              {callLoader ? (
                <center>
                  <img src={GraphLoader} width="150" maxHeight="250px" />
                </center>
              ) : (
                <BarChart
                  indexAxis="x"
                  chartLabels={zoneLabels}
                  chartLabel={["Hours"]}
                  ColorArray={["rgba(28, 255, 68, 0.43)"]}
                  BorderArray={["rgba(0, 225, 39, 1)"]}
                  chartData={hours}
                />
              )}
            </Card>
          </Col>
        </Row>

        <Row className="justify-content-start">
          {/* <Col md={2} className='mb-3 mb-md-0'>
            <Form.Label>Search by date</Form.Label>
            <Form.Control type="date" value={date} max={new Date().toISOString().split("T")[0]} onChange={(e) => setDate(moment(e.target.value).format("YYYY-MM-DD"))} />
          </Col> */}
          <Col md={2} className="mb-3 mb-md-0">
            <Form.Label>Search by Start Date</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) =>
                setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
              }
            />
          </Col>
          <Col md={2} className="mb-3 mb-md-0">
            <Form.Label>Search by End Date</Form.Label>
            <Form.Control
              type="date"
              value={endDate}
              min={startDate ? startDate : ""}
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) =>
                setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
              }
            />
          </Col>
          <Col md={2}>
            <Form.Label>Search by zone</Form.Label>
            <Form.Select onChange={(e) => setZone(e.target.value)}>
              <option value="" selected disabled>
                Select zone
              </option>
              {zoneList !== undefined &&
              zoneList.length > 0 &&
              zoneList !== null ? (
                zoneList?.map((row) => {
                  return (
                    <option value={row?.zonekey}>
                      {row?.zonename + -row?.zoneno}
                    </option>
                  );
                })
              ) : (
                <option value=""></option>
              )}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <div className="d-flex justify-content-center align-items-center mt-2 mb-1">
            <h6 className="mb-0" style={{ marginRight: "20px" }}>
              Total calls : {totalCallCount ? totalCallCount : 0}
            </h6>
            <h6 className="mb-0">
              Total users : {totalUsersCount ? totalUsersCount : 0}
            </h6>
          </div>
        </Row>

        <Row className="mt-3 mb-5">
          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: "72vh" }}>
              <Table responsive="lg" hover>
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th style={{ width: "maxContent" }}>Name</th>
                    <th>Mobile No.</th>
                    <th>Zone</th>
                    <th> Gat</th>
                    <th>Role</th>
                    <th style={{ whiteSpace: "nowrap" }}>Calling Count</th>
                    <th>Talk Duration</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {loader ? (
                  <Spinner variant="primary" className="mt-2" />
                ) : (
                  <tbody>
                    {users !== null && users !== undefined && users.length > 0
                      ? users.map((row, index) => {
                          return (
                            <tr
                              style={{ textAlign: "center", cursor: "pointer" }}
                            >
                              <td>{index + 1}</td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {row?.name}
                              </td>
                              <td>{row?.contactNo}</td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {row?.zonename}
                              </td>
                              <td
                                style={{
                                  maxWidth: "150px",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row?.gatname.toString()}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {row?.usertype_name}
                              </td>
                              <td>{row?.callingCount}</td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {row?.talkDuration}
                              </td>
                              <td
                                onClick={() =>
                                  navigate("/dashboard/call-log-report", {
                                    state: row,
                                  })
                                }
                              >
                                <Icon.Eye size={22} />
                              </td>
                            </tr>
                          );
                        })
                      : "No call logs found"}
                  </tbody>
                )}
              </Table>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default CallLog;

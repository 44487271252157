import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Spinner,
  Table,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import Pagination from "../Common/Pagination";

const NewVisitReport = () => {
  const userState = useSelector((state) => state.loginData.userData);
  const financialYear = useSelector(state => state.activeData.activeMenu.activeTab)
  const navigate = useNavigate();

  // Variables for Zone-Gat Visitors Data ------------
  const [contractor, setContractor] = useState();
  const [loader, setLoader] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState(0);

  // Search variables----
  const [searchMobile, setSearchMobile] = useState("");
  const [searchMobErr, setSearchMobErr] = useState(false);
  const [searchPropety, setSearchPropety] = useState("");
  const [searchPropErr, setSearchPropErr] = useState(false);

  // function for pagination

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  //   useEffect API Calls
  useEffect(() => {
    getVisitReportList();
  }, [docPerPage, pageNo]);

  // get List API
  const getVisitReportList = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL +
        "/billDistribution/getAllBillDistributionsPGN",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          propertyCode: searchPropety,
          visitingPersonContactNo: searchMobile,
          documentsPerPage: docPerPage,
          page: pageNo,
          financialYear : financialYear
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setContractor(response);
          setNoOfPages(response.noOfPages);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  const searchByMobile = async () => {
    if (searchMobile.length === 10) {
      getVisitReportList();
    } else {
      setSearchMobErr(true);
    }
  };

  const searchByProperty = async () => {
    if (searchPropety.length > 0) {
      getVisitReportList();
    } else {
      setSearchPropErr(true);
    }
  };

  return (
    <div className="visitReport">
      <ToastContainer />
      <Container>
        <Row className="justify-content-center">
                  {/* <Col md={3} className="mb-3 mb-md-0">
          <div className="d-flex">
            <Form.Control
              placeholder="mobile number"
              value={searchMobile}
              className="me-2"
              onChange={(e) => {
                if (
                  !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) &&
                  e.target.value.length <= 10
                ) {
                  setSearchMobile(e.target.value);
                }
                e.target.value.length >= 0
                  ? setSearchMobErr(false)
                  : setSearchMobErr(true);
              }}
            />
            <Button className="btn-submit" onClick={searchByMobile}>
              <Icon.Search size={14} className="me-2" />
              Search
            </Button>
          </div>
          {searchMobErr ? <p>Please Enter Valid Mobile Number</p> : null}
        </Col> */}

        <Col
          md={4}
          className="searchCard m-2"
          style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}
        >
          <div className="label mb-2 mx-auto">
            <p>Search by Property Code</p>
          </div>
          <div className="d-flex mt-1">
            <Form.Control
              placeholder="Property Code"
              pattern="[0-9]*"
              value={searchPropety}
              onChange={(e) => {
                if (!/^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                  e.target.setCustomValidity("Please enter only numbers.");
                } else {
                  e.target.setCustomValidity("");
                  setSearchPropety(e.target.value);
                }
              }}
            />

            <Button
              className="btn-submit ms-3"
              onClick={() => searchByProperty()}
            >
              <Icon.Search className="me-2" size={14} />
              Search
            </Button>
          </div>
          {searchPropErr ? (
            <p className="errMsg">Please enter valid property code</p>
          ) : null}
        </Col>
        </Row>
        <Row className="visitors mt-2">
          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: "65vh" }}>
              <Table responsive="lg" hover>
                <thead>
                  <th>Sr No.</th>
                  <th>Propety Code</th>
                  <th>Propety Owner Name</th>
                  <th>Propety Owner Contact No</th>
                  <th>Propety Address</th>
                  <th>Zone Name</th>
                  <th>Gat Name</th>
                  <th>Visit Person Name</th>
                  <th>Visit Date</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {loader ? (
                    <center>
                      <Spinner />
                    </center>
                  ) : contractor?.data && contractor.data.length > 0 ? (
                    contractor.data.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {pageNo !== 1 ? (
                              <> {index + 1 + docPerPage * (pageNo - 1)}</>
                            ) : (
                              <>{index + 1}</>
                            )}
                          </td>
                          <td>{row?.propertyCode ? row?.propertyCode : "-"}</td>
                          <td>{row?.propertyName ? row?.propertyName : "-"}</td>
                          <td>
                            {row?.propertyContactNo
                              ? row?.propertyContactNo
                              : "-"}
                          </td>
                          <td>
                            {row?.propertyAddress ? row?.propertyAddress : "-"}
                          </td>
                          <td>{row?.zonename ? row?.zonename : "-"}</td>
                          <td>{row?.gatname ? row?.gatname : "-"}</td>
                          <td>
                            {row?.visitingPersonName
                              ? row?.visitingPersonName
                              : "-"}
                          </td>
                          <td>
                            {row?.visitDate
                              ? moment(row?.visitDate).format("DD MMM YYYY")
                              : "-"}
                          </td>
                          <td className="text-center">
                            <Icon.Eye
                              style={{ cursor: "pointer" }}
                              size={25}
                              onClick={() =>
                                navigate("/dashboard/view-visit-report", {
                                  state: {
                                    report: row,
                                    comesFrom: "Visit Report",
                                  },
                                })
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>No data found</tr>
                  )}
                </tbody>
              </Table>
            </div>
            <Row>
              <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                <h6 className="text-start mb-0">
                  <Icon.People className="me-2" />
                  Total -{" "}
                  <strong>{contractor?.count ? contractor?.count : 0}</strong>
                </h6>
              </Col>
              <Col md={8} className="d-flex justify-content-end">
                <Pagination
                  currentPage={pageNo}
                  totalPages={noOfPages}
                  onPageChange={handlePageChange}
                  onDocsPerPage={handleDocsPerPage}
                  docsPerPage={docPerPage}
                />
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default NewVisitReport;
